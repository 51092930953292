<template>
  <v-card
    class="mx-auto"
    max-width="800"
    rounded="false"
    flat
  >
    <v-row  v-if="showPersonal">
      <v-col cols="6">
        <v-img class="ruka" 
          :src="require(`@/assets/ruka.gif`)" 
          width="380px"
        ></v-img>
    <v-card-title mt="100">
      Vítám Vás na mém webu
    </v-card-title>
      </v-col>

      <v-col cols="6">
        <v-img
          :src="require(`@/assets/oblicej.jpg`)" 
          width="400px"
        ></v-img>
        <v-card-subtitle>
      <img src="../assets/born.png" class="home_icon"> 2. 10. 1964 Prostějov
      <br>
      <img src="../assets/home.png" class="home_icon">Horňátecká 8, 180 00 Praha 8 - Kobylisy
    
    </v-card-subtitle>
      </v-col>
    </v-row>

    <v-card-actions>      
        <v-btn
          @click="showPersonal = !showPersonal, show = !show"
          depressed
          color="#EEEEEE"
          id="ome_btn"
          to="/viceome"
        >
        {{ show ? "... méně o mě )" : "... více o mě" }}
        </v-btn>
      
        <v-btn
          @click="showPersonal = false, show = false"
          depressed
          color="#EEEEEE"
          to="/obrazy"
        > 
        obrazy
        </v-btn>

        <v-btn
          @click="showPersonal = false, show = false"
          depressed
          color="#EEEEEE"
          to="/grafika"
        >
        grafika
        </v-btn>

        <v-btn
          @click="showPersonal = false, show = false"
          depressed
          color="#EEEEEE"
          to="/sochy"
        >
        sochy
        </v-btn>

        <v-btn
          @click="showPersonal = false, show = false"
          depressed
          color="#EEEEEE"
          to="/volna"
        >
        volná tvorba
        </v-btn>

        <v-btn
          @click="showPersonal = false, show = false"
          depressed
          color="#EEEEEE"
          to="/kontakt"
        >
        kontakt
        </v-btn>
    </v-card-actions>  
  </v-card>
</template>

<script>


export default {
  name: 'Personal',
  data: () => ({
      show: false,
      showPersonal: true,
  }),

}
</script>

<style scoped>

.col{
  padding: 0;
  margin: 0;
}
.ruka{
  position: absolute;
  top: 135px;
  left: 20px;

}
.v-card__subtitle{
  padding: 0;
}
.v-card__title{
  position: absolute;
  top: 510px;
  left: 10px;
  font-weight: 900;
  font-size: 25px;
}
.v-card__actions{
  justify-content: flex-end;
  background-color:#EEEEEE;
  padding: 0;
}
.row{
  margin-bottom: 20px;
}
ul{
  padding-left: 30px;
}
#ome_btn{
  position: absolute;
  left: 0;
}
.home_icon{
  width: 5%;
  padding-right: 5px;
}
h2{
  margin-bottom: 5px;
}

</style>
