<template>
  <div id="app">
    <Personal/>
  </div>
</template>

<script>
import Personal from '../components/Personal';

export default {
  name: 'App',
  components: {
    Personal
  },
  data: () => ({
  }),
};
</script>